<!--
 * @Description: 详情
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-04 19:58:54
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/sale/mods/detail/index.vue
-->
<template>
  <el-dialog title="详情" :visible.sync="shows" width="600px" append-to-body>
    <el-tabs v-model="activeName">
      <el-tab-pane label="客户信息" name="1">
        <CustomInfoView :data="data" />
      </el-tab-pane>
      <el-tab-pane label="跟进记录" name="2">
        <FollowLogView :data="data" />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import CustomInfoView from "./customInfo.vue";
import FollowLogView from "./followLog.vue";
export default {
  components: {
    CustomInfoView,
    FollowLogView,
  },
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      activeName: "1",
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
        if (!newValue) {
          this.$emit("update");
        }
      },
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
