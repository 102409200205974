var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"私信对话","visible":_vm.shows,"width":"900px","top":"10vh"},on:{"update:visible":function($event){_vm.shows=$event}}},[_c('div',{staticClass:"dialogue-view"},[_c('div',{staticClass:"dialogue-classify"},_vm._l((_vm.userList),function(item){return _c('div',{key:item.account_id,class:[
          'classify-item',
          { 'classify-active': item.open_id === _vm.userItem.open_id },
        ],on:{"click":function($event){return _vm.handleUser(item)}}},[_c('div',{staticClass:"classify-img"},[_c('img',{attrs:{"src":item.user_account.avatar,"alt":""}})]),_c('div',{staticClass:"classify-name"},[_vm._v(_vm._s(item.user_account.account))])])}),0),_c('div',{staticClass:"dialogue-users"},_vm._l((_vm.userChatList),function(item){return _c('div',{key:item.open_id,class:[
          'user-item',
          { 'user-active': item.open_id === _vm.userChatItem.open_id },
        ],on:{"click":function($event){return _vm.handleSwitch(item)}}},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.avatar,"alt":""}})]),_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.nick_name))])])}),0),_c('div',{staticClass:"dialogue-content"},[_c('div',{staticClass:"content-top"},[_vm._l((_vm.userChatHistoryList),function(item){return _c('div',{key:item.id},[(item.type === 'text' && item.scene === 'receive')?_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"content-time"},[_vm._v(_vm._s(item.created_at))]),_c('div',{staticClass:"content-text"},[_vm._v(_vm._s(item.content))])]):_vm._e(),(item.type === 'text' && item.scene === 'send')?_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"content-time",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(item.created_at)+" ")]),_c('div',{staticClass:"content-text",staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(item.content)+" ")])]):_vm._e(),(
              item.type === 'retain_consult_card' && item.scene === 'receive'
            )?_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"content-time"},[_vm._v(_vm._s(item.created_at))]),_c('div',{staticClass:"content-card"},[_c('div',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/card.jpg"),"alt":""}})]),_c('div',{staticClass:"card-title"},[_vm._v("致力云短视频推广")]),(item.content.length)?_c('div',{staticClass:"card-list"},_vm._l((item.content || []),function(cell,index){return _c('div',{key:index,staticClass:"card-item"},[_vm._v(" "+_vm._s(cell.label)+"："+_vm._s(cell.value)+" ")])}),0):_c('div',{staticClass:"card-list"},[_c('div',{staticClass:"card-item"},[_vm._v("请输入姓名")]),_c('div',{staticClass:"card-item"},[_vm._v("请输入电话")]),_c('div',{staticClass:"card-item"},[_vm._v("请请选择城市")])])])]):_vm._e(),(
              item.type === 'retain_consult_card' && item.scene === 'send'
            )?_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"content-time",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(item.created_at)+" ")]),_c('div',{staticClass:"content-card",staticStyle:{"float":"right"}},[_c('div',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("../../../../../../assets/img/card.jpg"),"alt":""}})]),_c('div',{staticClass:"card-title"},[_vm._v("致力云短视频推广")]),(item.content.length)?_c('div',{staticClass:"card-list"},_vm._l((item.content || []),function(cell,index){return _c('div',{key:index,staticClass:"card-item"},[_vm._v(" "+_vm._s(cell.label)+"："+_vm._s(cell.value)+" ")])}),0):_c('div',{staticClass:"card-list"},[_c('div',{staticClass:"card-item",staticStyle:{"color":"#aaa"}},[_vm._v("请输入姓名")]),_c('div',{staticClass:"card-item",staticStyle:{"color":"#aaa"}},[_vm._v("请输入电话")]),_c('div',{staticClass:"card-item",staticStyle:{"color":"#aaa"}},[_vm._v("请请选择城市")])])])]):_vm._e()])}),_c('div',{attrs:{"id":"id_bottom"}})],2),_c('div',{staticClass:"textarea-card"},[_c('i',{staticClass:"el-icon-postcard",on:{"click":_vm.handleCard}})]),_c('div',{staticClass:"content-bottom"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textarea),expression:"textarea"}],attrs:{"type":"textarea","placeholder":"请输入内容"},domProps:{"value":(_vm.textarea)},on:{"input":function($event){if($event.target.composing)return;_vm.textarea=$event.target.value}}}),_c('div',{staticClass:"sending",on:{"click":function($event){return _vm.handleReply()}}},[_vm._v("回 复")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }