<!--
 * @Description: 跟进记录
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-04 20:13:37
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/sale/mods/detail/followLog.vue
-->
<template>
  <div>
    <el-button type="primary" size="mini" @click="handleAdd"
      >添加跟进记录</el-button
    >
    <el-table
      :data="tableData"
      stripe
      max-height="300"
      style="width: 100%; margin-top: 10px"
      v-loading="isLoading"
    >
      <el-table-column
        type="index"
        label="序号"
        width="55px"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="content"
        show-overflow-tooltip
        label="跟进内容"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="follow_up_at"
        show-overflow-tooltip
        label="跟进时间"
        align="center"
      >
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <!-- 添加跟进记录 -->
    <AddFollowView
      v-if="add.show"
      :show.sync="add.show"
      v-bind="add"
      @update="getList"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../../utils/request";
import AddFollowView from "./addFollow.vue";

const URL = {
  list: "api/customer/userClueInteract/followUpList",
};
export default {
  components: {
    AddFollowView,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      add: {
        show: false,
        data: null,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          clue_id: this.data.id,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleAdd() {
      this.add = {
        show: true,
        data: this.data,
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
