<!--
 * @Description: 线索&互动
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-19 20:25:50
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/sale/index.vue
-->
<template>
  <div class="app-wrapper">
    <div class="wrapper-view">
      <div>
        <div class="wrapper-title">线索&互动</div>
        <el-form
          :model="queryParams"
          ref="queryForm"
          size="small"
          :inline="true"
          style="margin-top: 20px"
        >
          <el-form-item label="账号" prop="to_user_id">
            <el-select
              v-model="queryParams.to_user_id"
              style="width: 150px"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in userList"
                :key="item.account_id"
                :label="item.user_account.account"
                :value="item.open_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="互动类型" prop="system_event">
            <el-select
              v-model="queryParams.system_event"
              style="width: 150px"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                key="im_msg"
                label="私信"
                value="im_msg"
              ></el-option>
              <el-option
                key="im_msg_card"
                label="私信留资卡片"
                value="im_msg_card"
              ></el-option>
              <el-option
                key="new_video_digg"
                label="用户点赞"
                value="new_video_digg"
              ></el-option>
              <el-option
                key="new_follow_action"
                label="用户关注"
                value="new_follow_action"
              ></el-option>
              <el-option
                key="item_comment_reply"
                label="评论回复"
                value="item_comment_reply"
              >               
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="最近互动时间:" prop="createTime">
            <el-date-picker
              align="center"
              style="width: 240px"
              v-model="queryParams.createTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getList(1)"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item>
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="goPages('Collect')"
            >
              <i
                class="el-icon-star-on"
                style="font-size: 20px; color: #fea76e"
              ></i>
              <span>我的收藏</span>
            </div>
          </el-form-item>
        </el-form>
        <el-table
          :data="tableData"
          stripe
          max-height="550"
          style="width: 100%"
          v-loading="isLoading"
        >
          <el-table-column prop="account" label="客户名称" align="center">
            <template slot-scope="{ row: { user_detail } }">
              <div class="custom-view">
                <div class="custom-img">
                  <img :src="user_detail.avatar" alt="" />
                </div>
                <div class="custom-info">
                  <div class="info-name" :title="user_detail.nick_name">
                    {{ user_detail.nick_name }}
                  </div>
                  <div class="info-address">
                    {{ user_detail.address }} {{ user_detail.sex_text }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="account" label="互动记录" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.system_event_text }}</div>
              <div>互动记录（{{ row.num }}次）</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="mobile"
            show-overflow-tooltip
            label="联系方式"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="account_name"
            show-overflow-tooltip
            label="来源账号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="updated_at"
            label="最近互动时间"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="account" label="是否跟进" align="center">
            <template slot-scope="{ row }">
              {{ row.follow_up_text }}
              <el-switch
                :value="row.follow_up === 2"
                :disabled="row.follow_up === 2"
                @change="handleOpts('follow', row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="opts" label="操作" align="center" width="200">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="text"
                @click="handleOpts('preview', row)"
                >详情</el-button
              >
              <el-button
                size="mini"
                type="text"
                @click="handleOpts('dialogue', row)"
                >发送私信</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="collect" align="center" width="60">
            <template slot-scope="{ row }">
              <i
                v-if="row.collect === 1"
                class="el-icon-star-on"
                style="font-size: 20px; color: #bbb; cursor: pointer"
                @click="handleCollect(row, 2)"
              ></i>
              <i
                v-else-if="row.collect === 2"
                class="el-icon-star-on"
                style="font-size: 20px; color: #fea76e; cursor: pointer"
                @click="handleCollect(row, 1)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="page.total > 0"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.size"
          @pagination="getList()"
        />
      </div>
    </div>

    <!-- 对话 -->
    <DialogueView
      v-if="dialogue.show"
      :show.sync="dialogue.show"
      v-bind="dialogue"
      @update="getList"
    />

    <!-- 详情 -->
    <DetailView
      v-if="detail.show"
      :show.sync="detail.show"
      v-bind="detail"
      @update="getList"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../utils/request";
import DialogueView from "./mods/dialogue";
import DetailView from "./mods/detail";

const URL = {
    list: "api/customer/userClueInteract/list",
    collect: "api/customer/userClueInteract/collect",
    user_list: "api/customer/userAccount/tiktokAccounts",
    markFollowUp: "api/customer/userClueInteract/markFollowUp",
  },
  QUERY = {
    createTime: [],
    to_user_id: null,
    mobile: null,
  };

export default {
  components: {
    DialogueView,
    DetailView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      userList: [],
      dialogue: {
        show: false,
        data: null,
      },
      detail: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
    this.getUserList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const {
        createTime: [beginTime = null, endTime = null],
        ...attrs
      } = this.queryParams;

      const params = {
        beginTime,
        endTime,
        ...attrs,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },

    handleOpts(type, row) {
      switch (type) {
        case "dialogue":
          this.dialogue = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "preview":
          this.detail = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "follow":
          this.handleMarkFollowUp(row);
          break;
        default:
          break;
      }
    },

    // 跟进
    async handleMarkFollowUp({ id }) {
      await HTTP({
        url: URL.markFollowUp,
        method: "post",
        data: {
          id,
          follow_up: 2,
        },
      });
      this.$message.success("操作成功");
      this.getList();
    },

    // 收藏/取消收藏
    async handleCollect({ id }, collect) {
      await HTTP({
        url: URL.collect,
        method: "post",
        data: {
          id,
          collect,
        },
      });
      this.$message.success("操作成功");
      this.getList();
    },

    // 账号列表
    async getUserList() {
      const res = await HTTP({
        url: URL.user_list,
        method: "get",
      });
      this.userList = res || [];
    },

    goPages(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-view {
  display: flex;
  align-items: center;
  .custom-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #eee;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .custom-info {
    width: calc(100% - 50px);
    text-align: left;
    .info-name {
      font-size: 15px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .info-address {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
}
</style>
