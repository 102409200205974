<!--
 * @Description: 私信对话
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-19 21:22:39
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/sale/mods/dialogue/index.vue
-->
<template>
  <el-dialog title="私信对话" :visible.sync="shows" width="900px" top="10vh">
    <div class="dialogue-view">
      <div class="dialogue-classify">
        <div
          :class="[
            'classify-item',
            { 'classify-active': item.open_id === userItem.open_id },
          ]"
          v-for="item in userList"
          :key="item.account_id"
          @click="handleUser(item)"
        >
          <div class="classify-img">
            <img :src="item.user_account.avatar" alt="" />
          </div>
          <div class="classify-name">{{ item.user_account.account }}</div>
        </div>
      </div>
      <div class="dialogue-users">
        <div
          :class="[
            'user-item',
            { 'user-active': item.open_id === userChatItem.open_id },
          ]"
          v-for="item in userChatList"
          :key="item.open_id"
          @click="handleSwitch(item)"
        >
          <div class="item-img">
            <img :src="item.avatar" alt="" />
          </div>
          <div class="item-name">{{ item.nick_name }}</div>
        </div>
      </div>
      <div class="dialogue-content">
        <div class="content-top">
          <div v-for="item in userChatHistoryList" :key="item.id">
            <div
              class="content-left"
              v-if="item.type === 'text' && item.scene === 'receive'"
            >
              <div class="content-time">{{ item.created_at }}</div>
              <div class="content-text">{{ item.content }}</div>
            </div>
            <div
              class="content-right"
              v-if="item.type === 'text' && item.scene === 'send'"
            >
              <div class="content-time" style="text-align: right">
                {{ item.created_at }}
              </div>
              <div class="content-text" style="float: right">
                {{ item.content }}
              </div>
            </div>
            <div
              class="content-left"
              v-if="
                item.type === 'retain_consult_card' && item.scene === 'receive'
              "
            >
              <div class="content-time">{{ item.created_at }}</div>
              <div class="content-card">
                <div class="card-image">
                  <img src="../../../../../../assets/img/card.jpg" alt="" />
                </div>
                <div class="card-title">致力云短视频推广</div>
                <div class="card-list" v-if="item.content.length">
                  <div
                    class="card-item"
                    v-for="(cell, index) in item.content || []"
                    :key="index"
                  >
                    {{ cell.label }}：{{ cell.value }}
                  </div>
                </div>
                <div class="card-list" v-else>
                  <div class="card-item">请输入姓名</div>
                  <div class="card-item">请输入电话</div>
                  <div class="card-item">请请选择城市</div>
                </div>
              </div>
            </div>
            <div
              class="content-right"
              v-if="
                item.type === 'retain_consult_card' && item.scene === 'send'
              "
            >
              <div class="content-time" style="text-align: right">
                {{ item.created_at }}
              </div>
              <div class="content-card" style="float: right">
                <div class="card-image">
                  <img src="../../../../../../assets/img/card.jpg" alt="" />
                </div>
                <div class="card-title">致力云短视频推广</div>
                <div class="card-list" v-if="item.content.length">
                  <div
                    class="card-item"
                    v-for="(cell, index) in item.content || []"
                    :key="index"
                  >
                    {{ cell.label }}：{{ cell.value }}
                  </div>
                </div>
                <div class="card-list" v-else>
                  <div class="card-item" style="color: #aaa">请输入姓名</div>
                  <div class="card-item" style="color: #aaa">请输入电话</div>
                  <div class="card-item" style="color: #aaa">请请选择城市</div>
                </div>
              </div>
            </div>
          </div>
          <div id="id_bottom"></div>
        </div>
        <div class="textarea-card">
          <i class="el-icon-postcard" @click="handleCard"></i>
        </div>
        <div class="content-bottom">
          <textarea
            type="textarea"
            v-model="textarea"
            placeholder="请输入内容"
          ></textarea>
          <div class="sending" @click="handleReply()">回 复</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { HTTP } from "../../../../../../utils/request";
import { genUuid } from "../../../../../../utils/common";
import moment from "moment";

const URL = {
  user_list: "api/customer/userAccount/tiktokAccounts",
  user_chat_list: "api/customer/userChat/list",
  history: "api/customer/userChat/history",
  send: "api/customer/userChat/send",
};
export default {
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "add",
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      textarea: "",
      userList: [],
      userItem: null,
      userChatList: [],
      userChatItem: null,
      userChatHistoryList: [],
      msgTime: null,
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
        if (!newValue) {
          if (this.msgTime) {
            clearInterval(this.msgTime);
          }
        }
      },
    },
  },
  created() {
    this.getUserList();
  },
  methods: {
    // 账号列表
    async getUserList() {
      const res = await HTTP({
        url: URL.user_list,
        method: "get",
      });
      this.userList = res || [];
      this.userItem =
        this.userList?.find((e) => e.open_id === this.data?.to_user_id) ||
        this.userList?.[0];
      this.getUserChatList();
    },

    handleUser(item) {
      this.userItem = item;
      this.getUserChatList();
      this.userChatHistoryList = [];
    },

    // 用户私信列表
    async getUserChatList() {
      const res = await HTTP({
        url: URL.user_chat_list,
        method: "get",
        data: {
          uid: this.userItem.open_id,
        },
      });
      this.userChatList = res || [];
      this.userChatItem =
        this.userChatList?.find((e) => e.open_id === this.data?.from_user_id) ||
        this.userChatList?.[0];
      if (this.userChatItem) {
        await this.getUserChatHistory();
        this.getBottom();
        this.handleRefresh();
      } else {
        if (this.msgTime) {
          clearInterval(this.msgTime);
        }
      }
    },

    async handleSwitch(item) {
      this.userChatItem = item;
      await this.getUserChatHistory();
      this.getBottom();
      this.handleRefresh();
    },

    // 用户私信消息列表
    async getUserChatHistory() {
      const res = await HTTP({
        url: URL.history,
        method: "get",
        data: {
          toUid: this.userItem.open_id,
          fromUid: this.userChatItem.open_id,
          page: 1,
          limit: 50,
        },
      });
      this.userChatHistoryList = (res?.data || []).reverse();
    },

    // 到最底部
    getBottom() {
      this.$nextTick(() => {
        document.getElementById(`id_bottom`).scrollIntoView({
          behavior: "auto",
        });
      });
    },

    // 刷新消息
    handleRefresh() {
      if (this.msgTime) {
        clearInterval(this.msgTime);
      }
      this.msgTime = setInterval(() => {
        this.getUserChatHistory();
      }, 4000);
    },

    // 回复
    async handleReply() {
      try {
        if (!this.textarea) return;
        await HTTP({
          url: URL.send,
          method: "post",
          data: {
            fromUid: this.userItem.open_id,
            toUid: this.userChatItem.open_id,
            type: 1,
            msg: this.textarea,
          },
        });
        this.userChatHistoryList.push({
          id: genUuid(),
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          content: this.textarea,
          type: "text",
          scene: "send",
        });
        this.getBottom();
        this.textarea = "";
        this.handleRefresh();
      } catch (e) {
        console.log(e);
      }
    },

    // 回复卡片
    async handleCard() {
      try {
        await HTTP({
          url: URL.send,
          method: "post",
          data: {
            fromUid: this.userItem.open_id,
            toUid: this.userChatItem.open_id,
            type: 8,
          },
        });
        this.userChatHistoryList.push({
          id: genUuid(),
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          content: [],
          type: "retain_consult_card",
          scene: "send",
        });
        this.getBottom();
        this.handleRefresh();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogue-view {
  display: flex;
  height: 500px;
  border: 1px solid rgb(220, 220, 220);
  .dialogue-classify {
    width: 100px;
    overflow-y: auto;
    background-color: #e6e2e7;
    .classify-item {
      width: 100%;
      padding: 10px;
      cursor: pointer;
      .classify-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #eee;
        margin: 0 auto;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .classify-name {
        text-align: center;
        margin-top: 10px;
      }
    }
    .classify-active {
      background-color: #eee;
    }
  }
  .dialogue-users {
    width: 200px;
    overflow-y: auto;
    background-color: #fff;
    .user-item {
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      cursor: pointer;
      .item-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #eee;
        margin-right: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-name {
        text-align: center;
      }
    }
    .user-active {
      background-color: #eee;
    }
  }
  .dialogue-content {
    width: calc(100% - 300px);
    border-left: 1px solid rgb(220, 220, 220);
    .content-top {
      height: 367px;
      overflow-y: auto;
      padding: 10px;
      border-bottom: 1px solid rgb(220, 220, 220);
      .content-left,
      .content-right {
        margin-bottom: 10px;
        margin-top: 10px;
        overflow: hidden;
        .content-text {
          background-color: #e0eaff;
          display: inline-block;
          padding: 10px;
          border-radius: 5px;
          margin-top: 10px;
        }
        .content-card {
          width: 250px;
          overflow: hidden;
          border-radius: 10px;
          background-color: #f2f2f2;
          margin-top: 10px;
          .card-image {
            width: 100%;
            height: 120px;
            overflow: hidden;

            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
          .card-title {
            padding: 16px;
            font-size: 18px;
            color: #000;
          }
          .card-list {
            margin: 0 16px;
            .card-item {
              background-color: #fff;
              padding: 10px;
              border-radius: 10px;
              margin-bottom: 15px;
              font-size: 14px;
              color: #000;
            }
          }
        }
      }
    }
    .textarea-card {
      padding: 10px 10px 0 10px;
      font-size: 20px;
      i {
        cursor: pointer;
      }
    }
    .content-bottom {
      width: 100%;
      height: 100px;
      position: relative;
      textarea {
        width: 100%;
        height: 97px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        background-color: #fff;
        padding: 10px;
      }
      .sending {
        width: 80px;
        height: 30px;
        border-radius: 5px;
        background-color: #2d78ef;
        color: #fff;
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
</style>
