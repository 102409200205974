<!--
 * @Description: 添加跟进记录
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-04 21:14:35
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/sale/mods/detail/addFollow.vue
-->
<template>
  <el-dialog
    title="添加跟进记录"
    :visible.sync="shows"
    width="500px"
    append-to-body
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      size="medium"
      ref="ruleForm"
      label-width="80px"
      class="demo-ruleForm"
    >
      <el-form-item label="跟进时间" prop="follow_up_at">
        <el-date-picker
          v-model="ruleForm.follow_up_at"
          type="datetime"
          style="width: 350px"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="跟进内容" prop="content">
        <el-input
          v-model="ruleForm.content"
          style="width: 350px"
          placeholder="请输入跟进内容"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button @click="shows = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { HTTP } from "../../../../../../utils/request";

const URL = {
  followUp: "api/customer/userClueInteract/followUp",
};

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      ruleForm: {
        content: "",
        follow_up_at: null,
      },
      rules: {
        content: [
          { required: true, message: "请输入跟进内容", trigger: "change" },
        ],
        follow_up_at: [
          { required: true, message: "请选择跟进时间", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  created() {},
  methods: {
    async submitForm(formName) {
      try {
        await this.$refs[formName].validate();
        await HTTP({
          url: URL.followUp,
          method: "post",
          data: {
            clue_id: this.data?.id,
            ...this.ruleForm,
          },
        });
        this.$message.success("添加成功");
        this.shows = false;
        this.$emit("update");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
