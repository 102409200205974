<!--
 * @Description: 客户信息
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-04 20:11:56
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/thread/sale/mods/detail/customInfo.vue
-->
<template>
  <div style="margin-top: 10px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      size="medium"
      ref="ruleForm"
      label-width="80px"
      class="demo-ruleForm"
    >
      <el-form-item label="客户名称" prop="fullName">
        <el-input
          v-if="editState === 1"
          v-model="ruleForm.fullName"
          style="width: 300px"
          placeholder="请输入客户名称"
        ></el-input>
        <span v-else>{{ ruleForm.fullName }}</span>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select
          v-if="editState === 1"
          v-model="ruleForm.sex"
          style="width: 300px"
          placeholder="请选择性别"
        >
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="2"></el-option>
          <el-option label="未知" :value="3"></el-option>
        </el-select>
        <span v-else>{{
          ruleForm.sex === 1
            ? "男"
            : ruleForm.sex === 2
            ? "女"
            : ruleForm.sex === 3
            ? "未知"
            : ""
        }}</span>
      </el-form-item>
      <el-form-item label="所在地区" prop="area">
        <el-input
          v-if="editState === 1"
          v-model="ruleForm.area"
          style="width: 300px"
          placeholder="请输入所在地区"
        ></el-input>
        <span v-else>{{ ruleForm.area }}</span>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          v-if="editState === 1"
          v-model="ruleForm.address"
          style="width: 300px"
          placeholder="请输入详细地址"
        ></el-input>
        <span v-else>{{ ruleForm.address }}</span>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input
          v-if="editState === 1"
          v-model="ruleForm.mobile"
          style="width: 300px"
          placeholder="请输入联系方式"
        ></el-input>
        <span v-else>{{ ruleForm.mobile }}</span>
      </el-form-item>
      <el-form-item v-if="editState === 1">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button
          @click="
            () => {
              editState = 0;
              getCustomer();
            }
          "
          >取消</el-button
        >
      </el-form-item>
      <el-form-item v-else>
        <el-button type="primary" @click="editState = 1">修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { HTTP } from "../../../../../../utils/request";

const URL = {
  customer: "api/customer/userClueInteract/customer",
  editCustomer: "api/customer/userClueInteract/editCustomer",
};
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      editState: 0,
      ruleForm: {
        fullName: "",
        sex: null,
        mobile: "",
        area: "",
        address: "",
      },
      rules: {
        fullName: [
          { required: true, message: "请输入客户名称", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入联系方式", trigger: "change" },
        ],
        area: [{ required: true, message: "请输入地区", trigger: "change" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.getCustomer();
  },
  methods: {
    async getCustomer() {
      const res = await HTTP({
        url: URL.customer,
        method: "get",
        data: {
          uid: this.data?.from_user_id,
        },
      });
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = res?.[key];
        }
      }
      this.ruleForm.fullName = res.full_name;
    },
    async submitForm(formName) {
      try {
        await this.$refs[formName].validate();
        await HTTP({
          url: URL.editCustomer,
          method: "post",
          data: {
            uid: this.data?.from_user_id,
            ...this.ruleForm,
          },
        });
        this.getCustomer();
        this.editState = 0;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
